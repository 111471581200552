<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                        width="55" label="#" >
      </el-table-column>
      <el-table-column prop="name" label="拓展详情" >
        <template #default="scope">
          <el-tag size="mini" >{{scope.row.name}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" width="110">
        <template #header>
          <el-button size="mini"  @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
        </template>
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
              <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
            </el-button-group>
          </div>

        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>

    </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="50%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-scrollbar max-height="calc(100vh - 160px)" style="padding-right: 15px;height:calc(100% - 30px) ">
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">
        <el-form-item label="详情名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
<!--        <el-upload-->
<!--            class="avatar-uploader"-->
<!--            action="http://hmk.api.ibcck.cn/api/uploadImage"-->
<!--            :show-file-list="false"-->
<!--        >-->
<!--          <el-icon  class="avatar-uploader-icon"></el-icon>-->
<!--        </el-upload>-->
        <el-form-item label="位置" prop="location">
          <el-select style="width: 100%" v-model="ruleForm.location" placeholder="">
            <el-option
                v-for="item in locationArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付模式" prop="pay_type">
          <el-select style="width: 100%" v-model="ruleForm.pay_type" >
            <el-option
                v-for="item in pay_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模式" prop="mode">
          <el-select style="width: 100%" v-model="ruleForm.mode" >
            <el-option
                v-for="item in mode"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="抢单类型" prop="grab_type">
          <el-select style="width: 100%" v-model="ruleForm.grab_type" >
            <el-option
                v-for="item in grab_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="">
          <el-input-number style="width: 100%" type="number" :min="0" v-model="ruleForm.sequence"></el-input-number>
        </el-form-item>
        <div style="width: calc(100% )">
          <ueItem :content="ruleForm.content" :name="'conten'" @getContent="getContent"></ueItem>
<!--          <vue-ueditor-wrap v-model="ruleForm.content" :config="editorConfig" editor-id="editor-demo-01"></vue-ueditor-wrap>-->
        </div>
      </el-form>
      </el-scrollbar>
      <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
      <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/productapiitem"//本页面接口
// import { ref } from 'vue';//富文本
import ueItem from '../ue/ueItem'
export default {
  name: 'brand',
  components:{
    ueItem,
  },
  data(){
    return{
      nowData:'',
      editorConfig:'',//富文本
      login:false,
      drawer:false,
      tableData:[],
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        name:'',
        location:0,
        grab_type:'',
        mode:'',
        pay_type:'',
        content:'',
        sequence:50,
      },
      locationArr:[
        {
          id:0,
          name:'前',
        },
        {
          id:1,
          name:'后',
        },
      ],
      pay_type:[
        {
          id:0,
          name:'预付'
        },
        {
          id:1,
          name:'现付'
        }
      ],
      mode:[
        {
          id:0,
          name:'报价'
        },
        {
          id:1,
          name:'一口价'
        }
      ],
      grab_type:[
        {
          id:0,
          name:'师傅报价'
        },
        {
          id:1,
          name:'平台指派'
        }
      ],
      rules:{
        name: [
          { required: true, message: '请填写名称', trigger: 'change' },
        ],
        grab_type: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        location: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        mode: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        pay_type: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      }
    }
  },
  created() {
    // this.editorConfig = {
    //   autoHeightEnabled: false,
    //   initialFrameHeight: 600,
    //   // 初始容器宽度
    //   zIndex:9999,
    //   initialFrameWidth: '100%',
    //   serverUrl: 'https://ueditor.szcloudplus.com/cos', // 服务端接口
    //   UEDITOR_HOME_URL: '/UEditor/', // 访问 UEditor 静态资源的根路径，可参考常见问题1
    // };
    this.onTable()
    this.keyupSubmit()
  },
  methods:{
    getContent(html){
      this.ruleForm.content=html
      console.log('父组件接受',html)
    },
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
      axios.getproductConData(form).then((res)=>{
        console.log(res)
        this.tableData=res.data.data
        console.log('this.tableData',this.tableData)
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm={
        name:'',
        location:0,
        grab_type:'',
        mode:'',
        pay_type:'',
        content:'',
        sequence:50,
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.name=row.name
      this.ruleForm.location=row.location
      this.ruleForm.grab_type=row.grab_type
      this.ruleForm.pay_type=row.pay_type
      this.ruleForm.sequence=row.sequence
      this.ruleForm.content=row.content
      this.drawer=true
    },

    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postaddProductCon(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditProductCon(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }


        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelProductCon({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
</style>